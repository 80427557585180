import Config from "../config/config";
import Cookies from 'universal-cookie';
import { faGameConsoleHandheld } from "@fortawesome/pro-light-svg-icons";

export class UserController {

    private static instance: UserController;

    /**
     * Can't create the object without the instance method to prevent direct init
     */
    private constructor() {}

    /**
     * Controlling the singleton
     */
    public static getInstance(): UserController {
        if (!UserController.instance) {
            UserController.instance = new UserController();
        }
        return UserController.instance;
    }

    public getUserDetails() {
        const url = Config.endpoints.base + Config.endpoints.list.user.update.replace('{id}', sessionStorage.user_id);
        const cookies = new Cookies();
        return new Promise((resolve,reject) => {
            fetch(url, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'DOLAPIKEY': cookies.get('apikey')
                }
            }).then(res => {
                return res.json();
            }).then(data => {
                if (data.error) {
                    resolve({statusCode: 401, data: data.error.message});
                }
                else {
                    resolve({statusCode: 200, data: data});
                }
            }).catch(error => {
                console.log("Failed to load user data", error);
            });
        });
    }

    public getUserFormData(socid:string) {
        const url = Config.endpoints.base + Config.endpoints.list.user.getData + '?id_soc='+socid;
        return new Promise((resolve,reject) => {
            fetch(url,{
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json'
                }
            }).then(res => {
                return res.json();
            }).then(data => {
                if (data.error) {
                    resolve({statusCode: 401, data: data.error.message});
                }
                else {
                    resolve({statusCode: 200, data: data});
                }
            }).catch(error => {
                console.log("Failed to load cadastre data", error);
            });
        });
    }

    public getUserForm() {
        const url = Config.endpoints.base + Config.endpoints.list.user.getForm;
        let body = {
            cadastre: 2
        };
        return new Promise((resolve, reject) => {
            fetch(url, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(body)
            }).then(res => {
                return res.json();
            }).then(data => {
                if (data.error) {
                    resolve({statusCode: 401, data: data.error.message});
                }
                else {
                    resolve({statusCode: 200, data: data});
                }
            }).catch(error => {
                console.log("Failed to load JSON form schema", error);
            });
        });
    }

    public saveSettings(data:any) {

        const url = Config.endpoints.base + Config.endpoints.list.user.saveSettings;

        let body = {
            id:sessionStorage.user_id,
            settings:data
        }

        return new Promise((resolve, reject) => {
            fetch(url, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(body)
                }).then(res => {
                    return res.json()
                }).then( data => {
                    if(data.error) {
                        resolve({statusCode:401, data:data.error.message});
                    }
                    else {
                        resolve({statusCode:200, data:data});

                    }
                }).catch(error => {
                    console.log("failed to fetch", error)
                });
        })
    }

    public updateStakeholderData(data:any,id:any) {
        const url = Config.endpoints.base + Config.endpoints.list.user.updateData;

        var sendObject = {};
        data.forEach((item:any) => {
            if (item.name === 'szervezet') {
                Object.assign(sendObject,{"Név": item.value});
            }
            else if (item.name === 'telepules') {
                Object.assign(sendObject,{"Település": [item.value]});
            }
            else if (item.name === 'rovid_leiras') {
                Object.assign(sendObject,{"Rövid leírás": item.value});
            }
            else if (item.name === 'email') {
                Object.assign(sendObject,{"E-mail (központi)": item.value});
            }
            else if (item.name === 'telefon') {
                Object.assign(sendObject,{"Tel. (központi)": item.value});
            }
            else if (item.name === 'fokategoria') {
                Object.assign(sendObject,{"Főkategória": [item.value]});
            }
            else if (item.name === 'kategoria') {
                Object.assign(sendObject,{"Kategória": [item.value]});
            }
            else if (item.name === 'alkategoria') {
                Object.assign(sendObject,{"Alkategória": [item.value]});
            }
            else if (item.name === 'cimke') {
                Object.assign(sendObject,{"Címke": [item.value]});
            }
            else if (item.name === 'honlap') {
                Object.assign(sendObject,{"Honlap": item.value});
            }
            else if (item.name === 'facebook') {
                Object.assign(sendObject,{"FB": item.value});
            }
            else if (item.name === 'instagram') {
                Object.assign(sendObject,{"Instagram": item.value});
            }
            else if (item.name === 'cim') {
                Object.assign(sendObject,{"Cím": item.value});
            }
            else if (item.name === 'location_lat') {
                Object.assign(sendObject,{"Location Google Lat": item.value});
            }
            else if (item.name === 'location_long') {
                Object.assign(sendObject,{"Location Google Long": item.value});
            }
            else if (item.name === 'relevans_projekt_url') {
                Object.assign(sendObject,{"Releváns projektek (URL)": item.value});
            }
            else if (item.name === 'relevans_projektek_leiras') {
                Object.assign(sendObject,{"Releváns projektek (leírás)": item.value});
            }
            else if (item.name === 'kontakt_szemely') {
                Object.assign(sendObject,{"Kontakt személy": item.value});
            }
        });

        const body = {
            id: parseInt(id), 
            json: JSON.stringify(sendObject)
        };

        const cookies = new Cookies();

        return new Promise((resolve, reject) => {
            fetch(url, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'DOLAPIKEY': cookies.get('apikey')
                },
                body: JSON.stringify(body)
                }).then(res => {
                    return res.json()
                }).then( data => {
                    if(data.error) {
                        resolve({statusCode:401, data:data.error.message});
                    }
                    else {
                        resolve({statusCode:200, data:data});

                    }
                }).catch(error => {
                    console.log("failed to fetch", error)
                });
        })
    }

    public updateUserData(data:any) {
        const url = Config.endpoints.base + Config.endpoints.list.user.update.replace('{id}', sessionStorage.user_id);

        var urlencoded = new URLSearchParams();

        Object.keys(data).forEach((key:any) => {
            urlencoded.append(key, data[key]);
        });

        const cookies = new Cookies();

        return new Promise((resolve, reject) => {
            fetch(url, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                    'DOLAPIKEY': cookies.get('apikey')
                },
                body:urlencoded
                }).then(res => {
                    return res.json()
                }).then( data => {
                    if(data.error) {
                        resolve({statusCode:401, data:data.error.message});
                    }
                    else {
                        resolve({statusCode:200, data:data});

                    }
                }).catch(error => {
                    console.log("failed to fetch", error)
                });
        })
    }
    
};

import React, { useEffect, useState, useRef } from 'react';
import BrowseLayout from '../screens/layouts/BrowseLayout';
import { useResizeDetector } from 'react-resize-detector';
import "leaflet-editable";
import ReactLeafletEditable from "react-leaflet-editable";
import { Map, TileLayer, LayerGroup } from "react-leaflet";
import "leaflet/dist/leaflet.css";

export default function Stakeholders() {
	const { width, height, ref } = useResizeDetector();

	const [tileData, setTileData] = useState(false);

	useEffect(() => {
		let tileDefault = new Array({
			ref: "tile_default",
			url: "//{a-c}.tile.openstreetmap.org/{z}/{x}/{y}.png",
			project_url: "//{a-c}.basemaps.cartocdn.com/light_all/{z}/{x}/{y}.png",
			name: "tile_default",
			id: 1,
			order: 10,
			title: "OSM Háttér",
			default_on: false
		});
		setTileData(tileDefault);

	}, [])  // eslint-disable-line

    const editRef = useRef();
    const [map, setMap] = React.useState();
  
    const editPolygon = () => {
      editRef.current.startPolygon();
    };
  
    const mapRef = useRef();
  
    useEffect(() => {
      if (!mapRef.current) return;
  
      setMap(mapRef.current.leafletElement);
    }, []);

	return (
	    <BrowseLayout>
            <ReactLeafletEditable ref={editRef} map={map}>
                <button onClick={editPolygon} className="editable-btn">
                    Create Polygon
                </button>
                <Map
                    style={{
                    height: "1000px",
                    backgroundColor: "",
                    flexGrow: "1",
                    cursor: `10`
                    }}
                    ref={mapRef}
                    editable={true}
                    zoom={4}
                    maxZoom={18}
                    center={[35, 105]}
                >
                    <LayerGroup>
                        <TileLayer
                            attribution='&amp;copy <a href="https://osm.org/copyright">OpenStreetMap</a> contributors'
                            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                        />
                        <TileLayer url="https://tiles.openseamap.org/seamark/{z}/{x}/{y}.png" />
                    </LayerGroup>
                </Map>
            </ReactLeafletEditable>
		</BrowseLayout>
	);
};

//Import interfaces
import Config from "../config/config";

export class ParkController {

    private static instance: ParkController;

    private constructor() {}

    /**
     * Controlling the singleton
     */
    public static getInstance(): ParkController {
        if (!ParkController.instance) {
            ParkController.instance = new ParkController();
        }
        return ParkController.instance;
    }


    public getParkPictures() {
        return [
            Config.endpoints.parkpicture1+this.makeRandomString(12),
            Config.endpoints.parkpicture2+this.makeRandomString(12)
        ];
    }


    public makeRandomString(length:number) {
        let result           = '';
        const characters       = '0123456789';
        let charactersLength = characters.length;
        for ( let i = 0; i < length; i++ ) {
            result += characters.charAt(Math.floor(Math.random() * charactersLength));
        }
        return result;
    }


    public getParkData() {
        const url = Config.endpoints.base + Config.endpoints.list.park;

        return new Promise((resolve, reject) => {
            fetch(url, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json'
                },
            }).then(res => {
                if(res.status === 200) {
                    return res.json()
                }else {
                    reject({statusCode:404, error:"Could not load parking data"})
                }
            }).then(res => {
                resolve({statusCode:200, data:res})
            })
        });
    }
    
}

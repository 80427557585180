import React, {useState} from 'react';
import LoginComponent from '../components/Login';
import connect from 'react-redux/es/connect/connect';
import { withRouter } from 'react-router-dom';
import {
    setUserAuth, 
    getUserName, 
    getUserPass, 
    addUserAuthWarning, 
    clearUserAuthWarning, 
    getUserLoginWarning, 
    addUserToken, 
    addUserProjects, 
    addUserRole, 
    setUserCadastreAuth,
    setUserCadastreData,
    setUserPhoto,
    setUserDetails,
    setUserSettings,
    setUserAccess,
    setUserRights
} from '../state/reducers/user';
import {setNewVersion} from '../state/reducers/ui';
import Cookies from 'universal-cookie';
import ReactPiwik from "react-piwik";
import Config from "../config/config";

import {LoginController} from '../controllers/LoginController';
import {KataszterController} from '../controllers/KataszterController';

function Login(props) {
    const [warning, setWarning] = useState("");
    const [loading, setLoading] = useState(false);

    let cookies = new Cookies();
    const LoginControllerInst = LoginController.getInstance();
    const CadastreControllerInst = KataszterController.getInstance();
    
    function handleCadastreAuth(cadastreList) {

        let temp = {};


        cadastreList.forEach(cadastre => {
            if(temp.hasOwnProperty(cadastre.ref)) {
                if(cadastre.right_short === "w") {
                    temp[cadastre.ref].w = true;
                }
                if(cadastre.right_short === "r") {
                    temp[cadastre.ref].r = true;
                }
            }else {
                temp[cadastre.ref] = {r:cadastre.right_short === "r" ? true : null, w:cadastre.right_short === "w" ? true : null}
            }
        })
        sessionStorage.setItem("cadastreAuth", JSON.stringify(temp));

        props.setUserCadastreAuth(temp)
    }

    function handleMessage(response, auth){
        setLoading(true);
        ReactPiwik.push(['setUserId', auth.username])
        props.clearUserAuthWarning();
        props.addUserToken(response.access_token);
        let projects = response.project;

        handleCadastreAuth(response.cadastre);

        props.setUserRights(response.rights)
        props.setUserPhoto(response.user.photo !== null ? response.user.photo : "default");
        props.setUserDetails(response.user);
        props.setUserAccess(response.frontend !== undefined ? response.frontend : {});

        //Add sessionStorage
        if(auth.remember) {
            cookies.set('username', auth.username, { path: '/', expires: new Date(new Date().setFullYear(new Date().getFullYear() + 1)) });
            cookies.set('password', auth.password, { path: '/', expires: new Date(new Date().setFullYear(new Date().getFullYear() + 1)) });
        }

        if(cookies.get("version") === undefined) {
            cookies.set('version', Config.version.number, { path: '/', expires:  new Date(new Date().setFullYear(new Date().getFullYear() + 1)) });
            props.setVersion(true);
        }else{
            if(!(Config.version.number === cookies.get("version"))) {
                //todo set some redux var
                cookies.set("version", Config.version.number, { path: '/', expires: new Date(new Date().setFullYear(new Date().getFullYear() + 1))});
                props.setVersion(true);
            }
        }
        let temp = {};

        //set api key
        cookies.set('apikey', response.api_key, { path: '/', expires: new Date(new Date().setFullYear(new Date().getFullYear() + 1)) })

        if(Object.keys(response.user.mapsettings).length) {
            props.setUserSettings(response.user.mapsettings);
        }

        //set everything to localstorage
        sessionStorage.setItem('user_id', response.user.id);
        sessionStorage.setItem('rights', JSON.stringify(response.rights));
        sessionStorage.setItem("username", auth.username);
        sessionStorage.setItem("password", auth.password);
        sessionStorage.setItem("token", response.access_token);
        sessionStorage.setItem("projects", JSON.stringify(projects));
        // sessionStorage.setItem("role", role);
        sessionStorage.setItem("photo",response.user.photo !== null ? response.user.photo : "default");
        sessionStorage.setItem('userAccess',  JSON.stringify(response.frontend));
        sessionStorage.setItem("userDetails",JSON.stringify(response.user));
        props.saveUserAuth(
            auth.username,
            auth.password,
            response.user.id
        );

        //Get cadastre data while login
        CadastreControllerInst.getAllCadastre().then(res => {
            res.data.forEach(cadastre => {
                temp[cadastre.cadastre_id] = cadastre;
                if(cadastre.projects.length) {
                    if(cadastre.icon !== "" && projects[cadastre.projects[0].id]) {
                        projects[cadastre.projects[0].id].icon = cadastre.icon
                    }
                }
                //Set style default value for every dynamic and static cadastre
                if(response.user.hasOwnProperty("mapsettings") && response.user.mapsettings !== null) {
                    if(response.user.mapsettings.map !== undefined) {
                        if(cadastre.cadastre_id in response.user.mapsettings.map.cadastreStyles) {
                            if(response.user.mapsettings.map.cadastreStyles[cadastre.cadastre_id] !== null) {
                                temp[cadastre.cadastre_id].map_settings["style"] = response.user.mapsettings.map.cadastreStyles[cadastre.cadastre_id].style;
                            }
                        }
                        else {
                            temp[cadastre.cadastre_id].map_settings["style"] = {stroke: 1, strokeColor:"#003bff", fillColor:{r:"255", g:"255", b:"0", a:"0.2"}};
                        }
                    }
                }
            });

            props.addUserProjects(projects);
            props.setUserCadastreData(temp);
            localStorage.setItem("cadastreData", JSON.stringify(temp));
            props.history.push("/dashboard");
            setLoading(false);
        });

    }

    function loginAuthentication(auth) {
        LoginControllerInst.login(auth.username, auth.password).then(response => {
            if(response.statusCode === 200) {
                handleMessage(response.data, auth);
            } else {
                setWarning("Hibás felhasználónév vagy jelszó.")
            }
        })
    }

    return (
        <LoginComponent
            position={props.position}
            center={props.center}
            zoom={props.zoom}
            handleLogin={loginAuthentication}
            warning={warning}
            client={this}
            loading={loading}
            />
    );
}

const mapStateToProps = state => ({
    currentUsername: getUserName(state),
    currentPassword: getUserPass(state),
    warning: getUserLoginWarning(state)
});

const mapDispatchToProps = dispatch => ({
    saveUserAuth: (username, password, isMD5, id) => {
        dispatch(setUserAuth(username, password, isMD5, id))
    },
    addUserAuthWarning: (warning) => {
        dispatch(addUserAuthWarning(warning))
    },
    clearUserAuthWarning: () => {
        dispatch(clearUserAuthWarning())
    },
    addUserToken: (token) => {
        dispatch(addUserToken(token))
    },
    addUserProjects: (projects) => {
        dispatch(addUserProjects(projects))
    },
    addUserRole: (role) => {
        dispatch(addUserRole(role))
    },
    setUserCadastreAuth: (cadastreAuth) => {
        dispatch(setUserCadastreAuth(cadastreAuth))
    },
    setUserCadastreData: (cadastreData) => {
        dispatch(setUserCadastreData(cadastreData))
    },
    setVersion: (newVersion) => {
        dispatch(setNewVersion(newVersion));
    },
    setUserPhoto:(photo) => {
        dispatch(setUserPhoto(photo))
    },
    setUserDetails:(details) => {
        dispatch(setUserDetails(details))
    },
    setUserSettings: (settings) => {
        dispatch(setUserSettings(settings))
    },
    setUserAccess: (access) => {
        dispatch(setUserAccess(access));
    },
    setUserRights: (rights) => {
        dispatch(setUserRights(rights));
    }
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(Login));



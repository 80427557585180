import React, { useState, useEffect } from 'react';
import { Button, Form,Row,Col, Modal } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import connect from 'react-redux/es/connect/connect';
import { history } from '../../state/store';
import { addMapFilter, setLoadedProjectLayers, setLoadedCadastreLayers, getMapLayerOptions } from '../../state/reducers/ui';
import Config from '../../config/config';
import { MessageHandler } from '../../controllers/MessageHandlerController';
import { AlertController } from '../../controllers/AlertController';
import { UserController } from '../../controllers/UserController';
import Select from 'react-select';

import { getUserPhoto, getUserDetails, getUserSettings, setUserDetails } from '../../state/reducers/user';

function SettingPopup(props) {

	const [activeHeaders, setActiveHeaders] = useState([]);
	const [messagesWarning, setMessagesWarning] = useState(false);
	const [messages, setMessages] = useState([]);
	const [editUserDetails, setEditUserDetails] = useState(true);
	const [alerts, _setAlerts] = useState([]);
	const [deleteAlertTrigger, setDeleteAlertTrigger] = useState("");
	const [userData, setUserData] = useState({lastname:'', firstname:'', email:'', user_mobile:''});
	const [showNotify, setShowNotify] = useState(false);
	const [userForm, setUserForm] = useState([]);
	const [cadastreID,setCadastreID] = useState("");

	const MessageHanderControllerInst = MessageHandler.getInstance();
	const AlertControllerInst = AlertController.getInstance();
	const UserControllerInst = UserController.getInstance();

	//refs
	const alertsRef = React.useRef(alerts);
	const setAlerts = data => {
		alertsRef.current = data;
		_setAlerts(data);
	};

	useEffect(() => {
		getUserFormSchema();

		MessageHanderControllerInst.getMessagesByUser().then(response => {
			if(response.statusCode === 200) {
				setMessagesWarning(false);
				setMessages(response.data);
			}
			else if(response.statusCode === 404) {
				setMessagesWarning("Nem található hozzászólás")
			}
			else {
				setMessagesWarning("A hozzászólásokat nem sikerült betölteni")
			}
		});

		/*AlertControllerInst.getAlerts().then(response => {
			setAlerts(response.data);

			setTimeout(() => {
				readAlerts();
			}, [6000]);
		});*/

		setUserData({lastname:props.userDetails.lastname, firstname:props.userDetails.firstname, email:props.userDetails.email, user_mobile:props.userDetails.user_mobile});
	}, []) // eslint-disable-line

    function getSavedFilters() {
        let avalibleFilters = props.userSettings.map.savedFilters;
        return avalibleFilters ? avalibleFilters : {};
    }

	function loadFilterToMap(key) {
		let loadFilter = getSavedFilters()[key.replace(" ", "-").replace(".", "-")];

		if(loadFilter.hasOwnProperty("filters")) {
			props.addMapFilter(loadFilter.filters);
			let ld = loadFilter.layerData
			if(ld !== undefined) {
				if(ld.loadedCadastreLayers.length) {
					ld.loadedCadastreLayers.forEach(layer => {
						ld.loadedCadastreLayers.filter(x => !props.layerOptions.loadedCadastreLayers.includes(x)).forEach(cadastre_ref => {
							props.setLoadedCadastreLayers(cadastre_ref)
						})
					})
				}

				if(ld.loadedProjectLayers.length) {
                    if(ld.loadedProjectLayers.length) {
                        props.setLoadedProjectLayers(ld.loadedProjectLayers);
                    }
				}
			}
		}else {
			props.addMapFilter(loadFilter)
		}

		props.closeSetting();
		history.push("/map")
	}

	function checkHeader(header){
		if(activeHeaders.includes(header)){

			let lista = activeHeaders.filter(value =>{

				if(header == value){ // eslint-disable-line
					return false;
				}
				else{
					return true;
				}
			})
			setActiveHeaders(lista);

		}
		else{
			setActiveHeaders([...activeHeaders,header]);
		}
	};

	function disableError() {
		setTimeout(() => {
			props.resetAlert();
		}, [10000])
	};


	function readAlerts() {
		let alertsToRead = []
		alertsRef.current.forEach(alert => {
			if(alert.read_at === null) {
				alertsToRead.push(alert.rowid)
			}
		});

		AlertControllerInst.readAlert(alertsToRead).then(response => {
			let read = alertsRef.current.map(x => {
				if(x.read_at !== null) {
					x.read_at = ''
				}
				return x;
			});
			setAlerts(read)
		});
	};

	function deleteAlert(id) {
		AlertControllerInst.deleteAlert([id]).then(response => {
			setAlerts(alerts.filter(x => x.rowid !== id));
			setDeleteAlertTrigger('');
		});
	};

	function saveUserData() {
		UserControllerInst.updateUserData(userData).then(response => {
			setUserData({lastname:response.data.lastname, firstname:response.data.firstname, email:response.data.email, user_mobile:response.data.user_mobile})
			props.updateUserData({...props.userDetails, lastname:response.data.lastname, firstname:response.data.firstname, email:response.data.email, user_mobile:response.data.user_mobile})
			//setEditUserDetails(false);
		})
	};

	function saveStakeholderData() {
		UserControllerInst.updateStakeholderData(userForm,cadastreID).then(response => {
			console.log(response);
			//setEditUserDetails(false);
		})
	}


	function getUserFormSchema() {
		UserControllerInst.getUserForm().then(response => {
			let schema = response.data[2].json;
			let form = JSON.parse(schema);
			UserControllerInst.getUserDetails().then(userData => {
				UserControllerInst.getUserFormData(userData.data.socid).then(socData => {
					const formData = JSON.parse(socData.data.json);
					//console.log(formData);
					//console.log('HEY!!!');
					/*const realData = Object.entries(formData).map(([key,value]) => {
						return value;
					});*/
					const rowid = socData.data.rowid;
					setCadastreID(rowid);
					const filledForm = form.formJson.map((item,index) => {
						if (item.name === 'szervezet') {
							item.value = formData['Név'];
						}
						else if (item.name === 'telepules') {
							item.value = formData['Település'][0];
						}
						else if (item.name === 'rovid_leiras') {
							item.value = formData['Rövid leírás'];
						}
						else if (item.name === 'email') {
							item.value = formData['E-mail (központi)'];
						}
						else if (item.name === 'telefon') {
							item.value = formData['Tel. (központi)'];
						}
						else if (item.name === 'fokategoria') {
							item.value = formData['Főkategória'][0];
						}
						else if (item.name === 'kategoria') {
							item.value = formData['Kategória'][0];
						}
						else if (item.name === 'alkategoria') {
							item.value = formData['Alkategória'][0];
						}
						else if (item.name === 'cimke') {
							item.value = formData['Címke'][0];
						}
						else if (item.name === 'honlap') {
							item.value = formData['Honlap'];
						}
						else if (item.name === 'facebook') {
							item.value = formData['FB'];
						}
						else if (item.name === 'instagram') {
							item.value = formData['Instagram'];
						}
						else if (item.name === 'cim') {
							item.value = formData['Cím'];
						}
						else if (item.name === 'location_lat') {
							item.value = formData['Location Google Lat'];
						}
						else if (item.name === 'location_long') {
							item.value = formData['Location Google Long'];
						}
						else if (item.name === 'relevans_projekt_url') {
							item.value = formData['Releváns projektek (URL)'];
						}
						else if (item.name === 'relevans_projektek_leiras') {
							item.value = formData['Releváns projektek (leírás)'];
						}
						else if (item.name === 'kontakt_szemely') {
							item.value = formData['Kontakt személy'];
						}
						else {
							item.value = '';
						}
						return item;
					});
					setUserForm(filledForm);
				});
			});
		});
	};

	const updateFieldChange = index => e => {
		let newArr = [...userForm];
		newArr[index].value = e.target.value;
		setUserForm(newArr);
	};

	const updateSelectChange = (selectedOption,index) => {
		let newArr = [...userForm];
		newArr[index].value = selectedOption.value;
		setUserForm(newArr);
	};

	return (
		<React.Fragment>
		<Modal show={props.shown} onHide={props.closeSetting} dialogClassName="profile-modal">
			<Modal.Body>
				<i variant="secondary" className="closebutton" onClick={props.closeSetting}><FontAwesomeIcon size="lg" icon={['fal', 'times']} /></i>
				<Row className="profile-header">
					<Col lg={12} className="header-button-group d-flex align-items-center justify-content-end">
						{/* <i><UtuLight className="utu_light" style={{width: '56px', padding: '2px',margin:'2px'}}/></i> */}
						<p className="mqtt-status"> <FontAwesomeIcon icon={['fas', 'circle']} color={props.mqttStatus === 'Csatlakozva' ? '#7ace71' : props.mqttStatus === 'Nincs kapcsolat' ? '#d17373' : 'yellow'}/>  <span>{props.mqttStatus}</span></p>
						<button className="notifications" onClick={ () => setShowNotify(!showNotify)}><FontAwesomeIcon size="2x" icon={['fas', 'comment']} /></button>
						{props.alerts !== '' && <div className="alert-message-profile">1</div>}
						{showNotify && <div className="notification-popup">
							<i className="close-button" onClick={() => setShowNotify(false)}><FontAwesomeIcon size="lg" icon={['fal', 'times']} /></i>
							<div className="title">
								<p>Értesítések</p>
							</div>
							<div className="notify-body">
								{props.alerts !== '' && <div className="current-alert"><h6>Értesítés</h6><p><FontAwesomeIcon icon={['fas', 'exclamation-triangle']} color={'red'}/> {props.alerts.title} - {props.alerts.desc}  {disableError()}</p></div>}
									<div className="alert-list">
										{alerts.length ? alerts.map(alert => {
											return(
												<div className="alert-list-element">
													<li>{alert.read_at === null ? <FontAwesomeIcon icon={['fas', 'exclamation-triangle']} color={'red'}/> : ""} {alert.alert.title} <span className="alert-delete-icon" role="button" style={{float:'right'}} onClick={() => setDeleteAlertTrigger(alert.rowid)}><FontAwesomeIcon icon={['fas', 'trash']} /></span>
														<p><span className="alert-desc">Részletek: {alert.alert.desc}</span></p>
														<p className="alert-date">{alert.date_creation}</p>
													</li>
													{deleteAlertTrigger === alert.rowid && 
													<div className="alert-delete-confirm">
														<p>Biztosan törli a kijelölt értesítést ? </p>
														<button className="yes" onClick={() => deleteAlert(alert.rowid)}>Igen</button>
														<button className="no" onClick={() => setDeleteAlertTrigger("")}>Nem</button>
													</div>}
												</div>
												
											);
										}) : <p>Nincsenek értesítések</p>}
									</div>
							</div>
						</div>}
					</Col>
					<Col lg={12} style={{zIndex:'100'}}>
						<div className="user-img">
							<img alt="user" src={props.userPhoto !== 'default' ? `data:image/jpeg;base64,${props.userPhoto}` : '/logos/def_user.png'}/>
						</div>
					</Col>
				</Row>
				<Row className="profile-body">
					<Col lg={9}>
					{!editUserDetails ?
						<div className="user-data">
							<div className="edit-icon">
								<i className="icon" role="button" onClick={() => setEditUserDetails(!editUserDetails)}><FontAwesomeIcon size="lg" icon={['fal', 'pencil-alt']} /></i>
							</div>
							<div>
								<p className="user-name">{userData.firstname} {userData.lastname}</p>
							</div>
							<div>
								<p className="email">{userData.email}</p>
							</div>
							<div>
								<p className="phone">{userData.user_mobile}</p>
							</div>
						</div>
						
						:
						<div className="edit-profile">
							{userForm.map((item,index) => {
								if (item.type === 'text') {
									return(
										<div className="form-group">
											<label className="form-label">{item.label}</label>
											<input className="form-control" type={item.type} name={item.name} required={item.required === true ? true : false} value={item.value} onChange={updateFieldChange(index)} />
										</div>
									);
								}
								else if (item.type === 'textarea') {
									return(
										<div className="form-group">
											<label className="form-label">{item.label}</label>
											<textarea className="form-control" name={item.name} required={item.required === true ? true : false} value={item.value} onChange={updateFieldChange(index)} />
										</div>
									);
								}
								else if (item.type === 'autocomplete' || item.type === 'select') {
									return(
										<div className="form-group">
											<label className="form-label">{item.label}</label>
											<Select options={item.values} name={item.name} value={{label: item.value, value: item.value}} className="fullwidth-select" onChange={(selectedOption) => updateSelectChange(selectedOption,index)} />
										</div>
									);
								}
							})}
							<Row className="profil-edit-button-group">
								<Button className="save" variant="primary" onClick={() => saveStakeholderData()}> Mentés</Button>
								<Button className="cancel" variant="warning" onClick={() => setEditUserDetails(false)} > Mégse</Button>
							</Row>
						</div>}
					</Col>
					<Col lg={3}>
						<div className="card-header align-items-center">
							Beérkezett üzenetek
						</div>
						<div className="message-container" style={{paddingTop: '10px'}}>
							<h4>Verzió {Config.version.number} <i>- {Config.version.date}</i></h4>
							{Config.version.message.headers.map((header,index) =>{
									return(
										<div>
											<h6><FontAwesomeIcon icon={['far', activeHeaders.includes(header) ? 'angle-down' : 'angle-right']} onClick={() => checkHeader(header)}  role = "button"/> <span style={{padding: '5px'}}>{header}</span></h6>
											<div>
												{activeHeaders.includes(header)?
												<ul>{
													Config.version.message.list[index].map((detail, index) => {
														if(detail[0] === "-") {
															return(<ul><li key={index} style={{fontSize: '12px',margin: '2px'}}>{detail.slice(1, detail.length)}</li></ul>)
														}else {
															return(<li key={index} style={{fontSize: '12px',margin: '2px'}}>{detail}</li>)

														}
													})
													}
												</ul>
													:
													null
												}
											</div>

										</div>
									)
							})}
						</div>
					</Col>
					<Col lg={6} >
						<div className="card-header align-items-center">
							Hozzászólásaim
						</div>
						<div className="comment-container">
							{messagesWarning && <div style={{textAlign:"center", padding:"20px"}}><p>{messagesWarning}</p></div>}
							{messages.length ?
								<div className="comments">
									{messages.map(message => {
										return(
											<div>
											<span>Feladat: {message.project_task_id}</span>
											<span style={{float:'right'}}>{message.modified_at}</span>													
											<br />													
											<span>Hozzászólás: {message.message}</span>
											</div>
										)
									})}
								</div>:
								<p>Még nincsenek hozzászólásai</p>}								
						</div>
					</Col>
					<Col lg={6}>
						<div className="card-header align-items-center">
							Mentett térkép beállítások
						</div>
						<div className="settings-container">
							{Object.keys(getSavedFilters()).length ?
								<ul className="saved-set-list">
									{Object.keys(getSavedFilters()).map( function(key, index) {
										return <li>
													<div className="scs"><img alt="save" src="save1.png"/></div>
													<div className="name">{key}</div>
													<div className="action" onClick={() => loadFilterToMap(key)} role="button">
														<FontAwesomeIcon icon={['far', 'arrow-square-right']} />
													</div>
												</li>
									})}
								</ul>
							:
							<p>Még nem mentett térképbeállitásokat</p>
							}
						</div>
					</Col>
				</Row>
			</Modal.Body>
		</Modal>
		</React.Fragment>
	);
};

const mapStateToProps = state => ({
	layerOptions: getMapLayerOptions(state),
	userPhoto: getUserPhoto(state),
	userDetails: getUserDetails(state),
	userSettings: getUserSettings(state)

});


const mapDispatchToProps = (dispatch) => ({
	addMapFilter: (filterObj) => {
		dispatch(addMapFilter(filterObj));
	},
	setLoadedProjectLayers: (projectid) => {
		dispatch(setLoadedProjectLayers(projectid))
	},
	setLoadedCadastreLayers : (cadastre_ref) => {
		dispatch(setLoadedCadastreLayers(cadastre_ref))
	},
	updateUserData: (details) => {
		dispatch(setUserDetails(details))
	}
});
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(SettingPopup);

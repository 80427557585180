import React from 'react';
import {useState} from 'react';
import { Map, TileLayer, Marker } from 'react-leaflet';
import Leaflet from 'leaflet';
import { useTranslation } from 'react-i18next';
import { ReactComponent as Logo } from '../assets/images/manageyourcity.svg';
import { ReactComponent as UmsLogo } from '../assets/images/ums.svg';
import { ReactComponent as UtuLogo } from '../assets/images/utu_dark.svg';
import Cookies from 'universal-cookie';
import sha256 from 'js-sha256';
import {Row, Col, Modal, Button, Form} from "react-bootstrap";
import { history } from '../state/store';
import axios from "axios";
import ReCAPTCHA from "react-google-recaptcha";
import Config from '../config/config';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

Leaflet.Icon.Default.imagePath = '../../node_modules/leaflet';

delete Leaflet.Icon.Default.prototype._getIconUrl;

Leaflet.Icon.Default.mergeOptions({
    iconRetinaUrl: require('../assets/images/bekescsaba_marker.svg'),
    iconUrl: require('../assets/images/bekescsaba_marker.svg'),
    shadowUrl: null
});

/**
 * Login Component
 * 
 * Basic Functionality :
 *  - Skeleton for Login Component
 * **/
const Login = ({position, center, zoom, handleLogin, warning, loading}) => {
        const { t } = useTranslation();
        const [show, setShow] = useState(false);
        const [recoveredPass, setRecoveredPass] = useState(false);
        const [message, setMessage] = useState("");
        const [showPassword, setShowPaessword] = useState(false);

        //ForgotPWModal
        const [recoverPasswordShow, setRecoverPasswordShow] = useState(false)
        const [useremail, setUseremail] = useState("")

        const cookies = new Cookies();
        const cusername = cookies.get("username");
        const cpassword = cookies.get("password");

        const recaptchaRef = React.createRef();

        const [auth, setAuth] = useState({
            username: cusername !== undefined ? cusername : null, 
            password: cpassword !== undefined ? cpassword : null, 
            remember:false
        });


        const handleClose = () => setShow(false);
        const handleShow = () => setShow(true);

        const recoverPasswordShowHandleShow = () => setRecoverPasswordShow(true);
        const recoverPasswordShowHandleClose = () => {
            setRecoverPasswordShow(false);
            setMessage("")
            setUseremail(null)
        }

        const handlePassInfoClose = () => setRecoveredPass(false);
        //const buildVersion = Date.getUTCFullYear()+Date.getMonth();
        const buildVersion = "2542";

        function logout() {
            cookies.remove("username");
            cookies.remove("password");
            history.push("/")
        }

        function recoverPassword() {
            if(useremail !== null && useremail !== "") {
                axios.post('/api/test', {
                    username: auth.username,
                })
                .then(function (response) {
                    setMessage("A jelszóváltoztatási kérést kiküldtük az adminisztrátornak. A további teendőket kérem várja e-mailben : " + auth.username)
                })
                .catch(function (error) {
                    setMessage("A jelszóváltoztatás jelenleg nem üzemel. Kérem keresse fel az Adminisztrátort a következő e-mail cimen : admin@tesztadmin.com")
                });
            }else {
                setMessage("Kérem adja meg a felhasználónevét.")

            }
        }

        function onChange2(value) {
            if(value) {
                handleLogin(auth);
            }
        }

        return (
            <React.Fragment>
            <Modal size="lg" show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                  <Modal.Title>Rólunk</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                <p>Ödvözöljük az oldalon.</p>
                </Modal.Body>                
            </Modal>
            <Modal size="lg" show={recoveredPass} onHide={handlePassInfoClose}>
                <Modal.Header closeButton>
                  <Modal.Title>Jelszóváltoztatás</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                <p>{message}</p>
                </Modal.Body>           
            </Modal>

            <div className="body login">
                <div className="login-backgorund">
                    <div className="login-asset">
                        <div className="horizontal-text">
                            <h1 className="heading">Tisza-tó</h1>
                            <h1 className="heading-2">partnerhálózat</h1>
                        </div>
                        <div className="w-form">
                            <form className="form" onSubmit={(evt) => {
                                            evt.preventDefault();
                                            recaptchaRef.current.execute()
                            }}>
                                <ReCAPTCHA
                                    ref={recaptchaRef}
                                    size="invisible"
                                    sitekey={Config.reCAPTCHA.public}
                                    onChange={onChange2}
                                />
                                <input type="text" id="username" name="username" className="text-field-login w-input" maxLength="256" placeholder="Felhasználónév" onChange={ (evt) => setAuth({...auth, username:evt.target.value}) } />
                                <input type="password" id="password" name="password" className="text-field-login w-input" maxLength="256" placeholder="Jelszó"  onChange={ (evt) => setAuth({...auth, password:sha256(evt.target.value)}) } />
                                <div className="login-horizontal">
                                    <input type="submit" value="Belépés" className="blue-heavy-button-password login w-button" />
                                    <input type="submit" value="Egyéb info" className="white-button-password w-button" />
                                </div>
                            </form>
                            <div className="w-form-done">
                                <div>Thank you! Your submission has been received!</div>
                            </div>
                            {warning && <div className="w-form-fail"><div>Felhsználónév vagy jelszó hibás!</div></div>}
                        </div>
                        <div>powered by Urban Management Solution - UTU interface</div>
                    </div>
                    <div className="footer wf-section">
                        <div className="footer-line"></div>
                        <div className="footer-container">
                            <a href="https://www.empersend.hu/" target="_blank" id="w-node-ea901cde-2dcf-1f6d-7fe7-0b2ebc1f2fdc-61c4aa31" className="brand footer w-nav-brand">
                                <img src="/Empersend.svg" alt="" className="logo_footer" />
                            </a>
                            <div id="w-node-ea901cde-2dcf-1f6d-7fe7-0b2ebc1f2fde-61c4aa31" className="social-icon-div">
                                <a href="https://www.facebook.com/empersend.studio" target="_blank"><img src="/Facebook.svg" alt="" className="social-icon" /></a>
                                <a href="https://www.linkedin.com/company/empersend-studio/" target="_blank"><img src="/Linkin.svg" alt="" className="social-icon" /></a>
                            </div>
                            <a href="mailto:empersned.studio@gmail.com?subject=TT%C3%93%20partners%C3%A9g" className="text-block w-inline-block">Lépj velünk kapcsolatba</a>
                        </div>
                    </div>
                </div>
            </div>

            <Modal size="lg" className="other-info-modal" show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <div className="logo-container"><UtuLogo className="utu-logo" /></div>
                    <Modal.Title>Üdvözöljük az UTU manageyourcity felületén.</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div dangerouslySetInnerHTML={{__html: Config.welcome_popup_text}} />
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" onClick={handleClose}>vissza</Button>
                </Modal.Footer>             
            </Modal>

            {/* Modal for recovering PW input */}
            <Modal size="md" show={recoverPasswordShow} onHide={recoverPasswordShowHandleClose}>
                <Modal.Header closeButton>
                  <Modal.Title>Jelszóváltoztatás</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                <Form.Group controlId="useremail">
                    <Form.Label>Felhasználónév</Form.Label>
                    <Form.Control type="email" placeholder="Adja meg a felhasználónevét" onChange={evt => setUseremail(evt.target.value)}/>
                </Form.Group>
                <p>{message}</p>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="danger" onClick={recoverPasswordShowHandleClose}>Bezárás</Button>
                    <Button variant="primary" onClick={recoverPassword}>Jelszóváltoztatás igénylése</Button>
                </Modal.Footer>
            </Modal>
            </React.Fragment>

        );
};

Login.propTypes = {};

export default Login;

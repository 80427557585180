import React, {useEffect, useState} from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { withTranslation } from 'react-i18next';
import connect from 'react-redux/es/connect/connect';
import {getNavigationState, toggle, toggleSidebar, getView, toggleSettings, closeSidebar} from '../state/reducers/ui';
import SupportPopup from './ui/SupportPopup';

import SidebarNavigation from "./ui/SidebarNavigationAlter"
import { history } from '../state/store';

import { ReactComponent as UtuLight } from '../assets/images/utu_light.svg';
import Config from "../config/config";
/**
 * SideBar  Component
 * 
 * Basic Functionality :
 *  - Create quick access sidebar elements for the dashboard containers.
 * **/
function Sidebar(props) {
  
    const [supportShown, setSupportShow] = useState(false);

    useEffect(() => {
        window.addEventListener("resize", handleResize);
    }, []);  // eslint-disable-line

    function hideSupport() {
        setSupportShow(false);
    }

    function handleClick(e) {
        e.preventDefault();
        const id = e.currentTarget.attributes.id.value;
        props.toggle(id);

    }

    function handleResize() {
        if(window.innerWidth < 1125) {
            if(props.navigationState.sidebar) {
                props.closeSidebar();
            }
        } else {
            if(!props.navigationState.sidebar) {
                props.toggleSidebar();
            }
        }
    }

    return (
        <React.Fragment>
            <SupportPopup closeSupport={hideSupport} shown={supportShown}/>
            <nav id="" className={props.navigationState.sidebar ? 'sidebar-wrapper' : 'sidebar-wrapper closed'}>
                <div className="sidebar-content">
                    <div className="sidebar-menu">
                    <ul className="main-menu">
                        <li className={props.path === "/dashboard" ? 'active': ''}>
                            <a id="dashboard" style={{cursor: 'pointer'}} onClick={() => history.push("/dashboard")} >
                                <div>
                                    <span><i><FontAwesomeIcon icon={['fas', 'home']} /></i></span> <span>Dashboard</span>
                                </div>
                            </a>
                        </li>

                        <SidebarNavigation toggled="smart" backgroundColor='#CA8DEE' icon={['fas', 'router']} name='Smart elemek' handleClick={handleClick} parent={props} route="/smart" target=""/>
                        {/* <SidebarNavigation toggled="kataszter" backgroundColor='#CA8DEE' icon={['fas', 'clipboard-list']} name='Kataszterek' handleClick={handleClick} parent={props} route="/kataszter"/> */}
                        {/* <SidebarNavigation toggled="ü" backgroundColor='#CA8DEE' icon={['fas', 'user-tie']} name='Ügyintézés' handleClick={handleClick} parent={props} route="/smart"/> */}
                        <SidebarNavigation toggled="thirdparty" backgroundColor='#CA8DEE' icon={['fas', 'link']} name='Külső szoftverkapcsolat' handleClick={handleClick} parent={props} route="/thirdparty" target=""/>
                        <SidebarNavigation toggled="citymarketing" backgroundColor='#CA8DEE' icon={['fas', 'building']} name='Városüzemeltetés' handleClick={handleClick} parent={props} route={Config.screens.dashboard.dashboardbox.citymarketing.route} target="_blank"/>

                        <li className="support-link" >
                            <a href="https://manageyourcity.com/?docs=utu-1-1-0-felhasznaloi-kezikonyv" id="support">
                                <div>
                                    <span><i><FontAwesomeIcon icon={['fas', 'info-circle']} /></i></span> <span>Kapcsolat</span>
                                </div>
                            </a>
                        </li>  
                        </ul>
                    </div>
                </div>
                <div>
                    <i><UtuLight className="utu_light" style={{width: '56px', padding: '2px',margin:'2px'}}/></i>
                </div>
                    
            </nav>
        </React.Fragment>
        );
}

const mapStateToProps = state => ({
    navigationState: getNavigationState(state),
    path: state.router.location.pathname,
    view: getView(state)
});

const mapDispatchToProps = (dispatch) => ({
    toggle: (id) => {
        dispatch(toggle(id));
    },
    toggleSidebar: () => {
        dispatch(toggleSidebar());
    },
    toggleSettings: () => {
        dispatch(toggleSettings())
    },
    closeSidebar: () => {
    	dispatch(closeSidebar());
	},
});

const TranslatedSidebar = withTranslation()(Sidebar);

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(TranslatedSidebar);

import React, {useState, useEffect} from 'react';
import { Row, Col } from "react-bootstrap";
import { ParkController } from "../../../controllers/ParkController";

function ParkDetails(props) {

    //Park
    const ParkControllerInst = ParkController.getInstance();
    const pictures = ParkControllerInst.getParkPictures();
    const [cameraPic1, setCameraPic1] = useState(pictures[0]);
    const [cameraPic2, setCameraPic2] = useState(pictures[1]);
    const [snapShotDate1, setSnapShotDate1] = useState('');
    const [snapShotDate2, setSnapShotDate2] = useState('');
    const [parkingPoints, setParkingPoints] = useState([]);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        setLoading(true);

        ParkControllerInst.getParkData().then(response => {
            if(response.statusCode === 200) {
                console.log(response);
                setParkingPoints([...response.data['OSZ-03-001'].places, ...response.data['OSZ-03-002'].places]);
                setSnapShotDate1(response.data['OSZ-03-001'].snapshot_last_update);
                setSnapShotDate2(response.data['OSZ-03-002'].snapshot_last_update);
                setLoading(false);
            }
        });
    },[]) // eslint-disable-line

    let pointBackgroundColor = 'green';

    return(
        <div className="dashboard-container park-container">
            <Row>
                <Col>
                    <h4>Parkolásmenedzsment</h4>
                </Col>
            </Row>
            <Row>
                <Col>
                    <div className="dashboard-card-body container park-container">
                        <div className="dashboard-card-inside">
                            <div className="dashboard-card-header">
                                <div className="map-container">
                                    <img src="/markers/utu_parking_map.png" alt="Parkolási térkép" className="fixed-size-map"></img>
                                    {parkingPoints.map((point) => {
                                        point.empty === '0' ? pointBackgroundColor = 'red' : pointBackgroundColor = 'green';
                                        return (<figure className="park-point" style={{top: point.coords.y+"px", left: point.coords.x+"px", background: pointBackgroundColor}}></figure>)
                                    })}
                                </div>
                            </div>
                        </div>
                    </div>
                </Col>
            </Row>
            <Row className="dasboard-container-row">
                <Col lg={6} md={6} sm={12} xs={12}>
                    <div className="dashboard-card-body container">
                        <div className="dashboard-card-inside">
                            <img className="camera-picture" src={cameraPic2}></img>
                            <code>OSZ-03-002 (Utolsó frissítés: {snapShotDate2})</code>
                        </div>
                    </div>
                </Col>
                <Col lg={6} md={6} sm={12} xs={12}>
                    <div className="dashboard-card-body container">
                        <div className="dashboard-card-inside">
                            <img className="camera-picture" src={cameraPic1}></img>
                            <code>OSZ-03-001 (Utolsó frissítés: {snapShotDate1})</code>
                        </div>
                    </div>
                </Col>
            </Row>
        </div>
    )
}

export default (ParkDetails);

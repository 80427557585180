import React, { useEffect, useState } from 'react';
import BrowseLayout from '../screens/layouts/BrowseLayout';
import MapContainer from '../containers/map/Map';
import ActiveFilterContainer from "../components/Project/ui/ActiveFilterContainer";
import ActiveCadastreFilterContainer from "../components/Map/ui/ActiveCadastreFilterContainer";
import { useResizeDetector } from 'react-resize-detector';
import { MapController } from '../controllers/MapController';

//Wrapper for map component to pre-load required data
export default function Map() {
	const {  height, ref } = useResizeDetector();

	const [tileData, setTileData] = useState(false);
	const MapControllerInst = MapController.getInstance();

	//Get all tiles avalible and load before map init
	useEffect(() => {
		let tileDefault = new Array({
			ref: "tile_default",
			url: "//{a-c}.tile.openstreetmap.org/{z}/{x}/{y}.png",
			project_url: "//{a-c}.basemaps.cartocdn.com/light_all/{z}/{x}/{y}.png",
			name: "tile_default",
			id: 1,
			order: 10,
			title: "OSM Háttér",
			default_on: false
		});
		setTileData(tileDefault);

		/*MapControllerInst.getTiles('Tisza-tó').then(res => {
			if(res.statusCode === 200) {
				setTileData(res.data)
			}
			else {
			}
		})*/
	}, [])  // eslint-disable-line

	return (
	    <BrowseLayout>
			<div ref={ref}>
				<div>
					<ActiveFilterContainer />
				</div>
				<div>
					<ActiveCadastreFilterContainer />
				</div>
			</div>
			<div className="filters-layer col-md-8 col-lg-8">
				<div className="col-md-8 col-lg-8">
					<div className="controls">
						<div key="b" className="map-setting">
						</div>
					</div>
				</div>
			</div>
			{tileData && <MapContainer tileConfig={tileData} filtersHeight={height}/>}
		</BrowseLayout>
	);
};
